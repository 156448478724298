import { TableHead, TableRow } from "@mui/material";
import { handleFilter } from "../../redux/reducers/ContractsReducer";
import { saveToCookieStorage } from "../../helper/cookie";
import { useDispatch, useSelector } from "react-redux";
import { StatusOption } from "../Contracts/ContractTableMain";
import { useTheme } from "../../ThemeContext";
import moment from "moment";
import GoogleIcon from "@mui/icons-material/Google";
import ClearIcon from "@mui/icons-material/Clear";
import "../../styles/ContractTable.css";
import { SortDirectionIcon } from "../Contracts/ParentTable/helper";
import TableCellWrapper from "./CellWrapper";
import TableExtraOption from "../TableExtraOption/TableExtraOption";
import MinMaxInput from "../MinMaxInput/MinMaxInput";
import { AppContext } from "../../provider/AppContext";
import { useContext } from "react";

const CustomHeader = () => {
  const { mode } = useTheme().themeState || {};
  const dispatch = useDispatch();
  const { isStaffer } = useContext(AppContext);

  const setFilter = (state) => {
    dispatch(handleFilter(state));
  };

  const {
    event: { ClientData, ClientType, EmployeeList, SCH_REG },
    Contracts: { filter = {} },
  } = useSelector((state) => state);
  const { sort, order, filterColArray } = filter;

  const mapDataOptions = (data, labelKey, valueKey) => {
    if (!Array.isArray(data)) return [];

    return data?.map((item) => ({
      label: item[labelKey],
      value: item[valueKey],
    }));
  };

  const ClientOptions = mapDataOptions(ClientData?.data, "name", "id");
  const SchOptions = mapDataOptions(SCH_REG?.data, "name", "key");
  const EmployeeOptions = mapDataOptions(EmployeeList, "firstName", "id");
  const ClientTypeOptions = mapDataOptions(ClientType?.data, "name", "id");

  // min max filters
  const updateFilterAndSave = (updates) => {
    const updatedFilter = { ...filter, ...updates, currentPage: 1 };
    setFilter(updatedFilter);
    saveToCookieStorage("filterKeys", updatedFilter);
  };

  const debounceSetMinMaxFilter = (value, name) => {
    updateFilterAndSave({ [name]: value, limit: 5 });
  };

  const onChangeCreateDate = (dates) => {
    const startDate = dates?.[0] && moment(dates[0]).format("MM-DD-YYYY");
    const endDate = dates?.[1] && moment(dates[1]).format("MM-DD-YYYY");
    updateFilterAndSave({
      startCreatedDate: startDate,
      endCreatedDate: endDate,
    });
  };

  const handleSortClick = (sort) => {
    const newSortOrder = sort && order === "ASC" ? "DESC" : "ASC";
    updateFilterAndSave({ sort, order: newSortOrder });
  };

  const onChangeDate = (dates) => {
    const startDate = dates?.[0] && moment(dates[0]).format("MM-DD-YYYY");
    const endDate = dates?.[1] && moment(dates[1]).format("MM-DD-YYYY");
    updateFilterAndSave({ startEventDate: startDate, endEventDate: endDate });
  };

  const onChangeSignDate = (dates) => {
    const startDate = dates?.[0] && moment(dates[0]).format("MM-DD-YYYY");
    const endDate = dates?.[1] && moment(dates[1]).format("MM-DD-YYYY");
    updateFilterAndSave({ startSignDate: startDate, endSignDate: endDate });
  };

  const handleFilterChange = (name, value) => {
    updateFilterAndSave({ [name]: value });
  };

  const handleClean = (name) => {
    updateFilterAndSave({ [name]: "" });
  };

  const {
    startCreatedDate,
    endCreatedDate,
    startEventDate,
    endEventDate,
    maxTotalCost,
    minTotalCost,
    startSignDate,
    endSignDate,
    minClientBudget,
    maxClientBudget,
    minProfit,
    maxProfit,
    contractStatus,
    maxProfitPercentage,
    minProfitPercentage,
  } = filter || {};

  return (
    <TableHead className="contract-overview-thead">
      <TableRow>
        <TableCellWrapper
          isHidden={!filterColArray?.includes("createdAt")}
          extraOption={
            <TableExtraOption
              type="dateRangePicker"
              value={
                startCreatedDate
                  ? [new Date(startCreatedDate), new Date(endCreatedDate)]
                  : []
              }
              placeholder="Select Date"
              onChange={onChangeCreateDate}
            />
          }
          onClick={() => handleSortClick("event.createdAt")}
        >
          Created{" "}
          <SortDirectionIcon
            {...{ order, isExpanded: sort === "event.createdAt" }}
          />
        </TableCellWrapper>

        <TableCellWrapper
          isHidden={!filterColArray?.includes("startDate")}
          extraOption={
            <TableExtraOption
              type="dateRangePicker"
              value={
                startEventDate
                  ? [new Date(startEventDate), new Date(endEventDate)]
                  : []
              }
              placeholder="Select Date"
              onChange={onChangeDate}
            />
          }
          onClick={() => handleSortClick("event.startDate")}
        >
          Event Date{" "}
          <SortDirectionIcon
            {...{ order, isExpanded: sort === "event.startDate" }}
          />
        </TableCellWrapper>

        <TableCellWrapper
          isHidden={!filterColArray?.includes("client_name")}
          extraOption={
            <TableExtraOption
              type="multiCascader"
              placeholder="Select"
              data={ClientOptions}
              value={filter?.client}
              onCheck={(values) => handleFilterChange("client", values)}
              onClean={() => handleClean("client")}
            />
          }
          onClick={() => handleSortClick("client.name")}
        >
          CLIENT
          <SortDirectionIcon
            {...{ order, isExpanded: sort === "client.name" }}
          />
        </TableCellWrapper>

        <TableCellWrapper
          isHidden={!filterColArray?.includes("client_type")}
          extraOption={
            <TableExtraOption
              type="multiCascader"
              placeholder="Select"
              data={ClientTypeOptions}
              value={filter?.clientType}
              onCheck={(values) => handleFilterChange("clientType", values)}
              onClean={() => handleClean("clientType")}
            />
          }
          onClick={() => handleSortClick("clientType.name")}
        >
          CLIENT TYPE
          <SortDirectionIcon
            {...{ order, isExpanded: sort === "clientType.name" }}
          />
        </TableCellWrapper>

        <TableCellWrapper
          isHidden={!filterColArray?.includes("region")}
          extraOption={
            <TableExtraOption
              type="multiCascader"
              placeholder="Select"
              data={SchOptions}
              value={filter?.schoolRegion}
              onCheck={(values) => handleFilterChange("schoolRegion", values)}
              onClean={() => handleClean("schoolRegion")}
            />
          }
          onClick={() => handleSortClick("sch-reg")}
        >
          SCH/REG
          <SortDirectionIcon {...{ order, isExpanded: sort === "sch-reg" }} />
        </TableCellWrapper>

        <TableCellWrapper
          isHidden={!filterColArray?.includes("sold_by")}
          extraOption={
            <TableExtraOption
              type="multiCascader"
              placeholder="Select"
              data={EmployeeOptions}
              value={filter?.soldBy}
              onCheck={(values) => handleFilterChange("soldBy", values)}
              onClean={() => handleClean("soldBy")}
            />
          }
          onClick={() => handleSortClick("soldBy.firstName")}
        >
          SOLD BY
          <SortDirectionIcon
            {...{ order, isExpanded: sort === "soldBy.firstName" }}
          />
        </TableCellWrapper>

        <TableCellWrapper
          isHidden={!filterColArray?.includes("created_by") || isStaffer}
          extraOption={
            <TableExtraOption
              type="multiCascader"
              placeholder="Select"
              data={EmployeeOptions}
              value={filter?.createdBy}
              onCheck={(values) => handleFilterChange("createdBy", values)}
              onClean={() => handleClean("createdBy")}
            />
          }
          onClick={() => handleSortClick("createdBy.firstName")}
        >
          CREATED BY
          <SortDirectionIcon
            {...{ order, isExpanded: sort === "createdBy.firstName" }}
          />
        </TableCellWrapper>

        <TableCellWrapper
          isHidden={!filterColArray?.includes("amount") || isStaffer}
          extraOption={
            <MinMaxInput
              valueMin={minClientBudget}
              valueMax={maxClientBudget}
              onChangeMin={(e) =>
                debounceSetMinMaxFilter(e.target.value, "minClientBudget")
              }
              onChangeMax={(e) =>
                debounceSetMinMaxFilter(e.target.value, "maxClientBudget")
              }
              mode={mode}
            />
          }
          onClick={() => handleSortClick("event.clientBudget")}
        >
          Amount
          <SortDirectionIcon
            {...{ order, isExpanded: sort === "event.clientBudget" }}
          />
        </TableCellWrapper>

        <TableCellWrapper
          isHidden={!filterColArray?.includes("cost") || isStaffer}
          extraOption={
            <MinMaxInput
              valueMin={minTotalCost}
              valueMax={maxTotalCost}
              onChangeMin={(e) =>
                debounceSetMinMaxFilter(e.target.value, "minTotalCost")
              }
              onChangeMax={(e) =>
                debounceSetMinMaxFilter(e.target.value, "maxTotalCost")
              }
              mode={mode}
            />
          }
          onClick={() => handleSortClick("event.totalCost")}
        >
          Cost
          <SortDirectionIcon
            {...{ order, isExpanded: sort === "event.totalCost" }}
          />
        </TableCellWrapper>

        <TableCellWrapper
          isHidden={!filterColArray?.includes("profit") || isStaffer}
          extraOption={
            <MinMaxInput
              valueMin={minProfit}
              valueMax={maxProfit}
              onChangeMin={(e) =>
                debounceSetMinMaxFilter(e.target.value, "minProfit")
              }
              onChangeMax={(e) =>
                debounceSetMinMaxFilter(e.target.value, "maxProfit")
              }
              mode={mode}
            />
          }
          onClick={() => handleSortClick("event.profit")}
        >
          Profit
          <SortDirectionIcon
            {...{ order, isExpanded: sort === "event.profit" }}
          />
        </TableCellWrapper>

        <TableCellWrapper
          isHidden={!filterColArray?.includes("profitMargin") || isStaffer}
          extraOption={
            <MinMaxInput
              valueMin={minProfitPercentage}
              valueMax={maxProfitPercentage}
              onChangeMin={(e) =>
                debounceSetMinMaxFilter(e.target.value, "minProfitPercentage")
              }
              onChangeMax={(e) =>
                debounceSetMinMaxFilter(e.target.value, "maxProfitPercentage")
              }
              mode={mode}
            />
          }
          onClick={() => handleSortClick("event.profitMargin")}
        >
          Profit%
          <SortDirectionIcon
            {...{ order, isExpanded: sort === "event.profitMargin" }}
          />
        </TableCellWrapper>

        <TableCellWrapper
          isHidden={!filterColArray?.includes("contract_status") || isStaffer}
          extraOption={
            <TableExtraOption
              type="multiCascader"
              placeholder="Select"
              data={StatusOption.map((option) => ({
                label: option,
                value: option,
              }))}
              value={contractStatus}
              onCheck={(values) => handleFilterChange("contractStatus", values)}
              onClean={() => handleClean("contractStatus")}
            />
          }
          onClick={() => handleSortClick("event.contractStatus")}
        >
          STATUS
          <SortDirectionIcon
            {...{ order, isExpanded: sort === "event.contractStatus" }}
          />
        </TableCellWrapper>

        <TableCellWrapper
          isHidden={!filterColArray?.includes("sign_date") || isStaffer}
          extraOption={
            <TableExtraOption
              type="dateRangePicker"
              value={
                startSignDate
                  ? [new Date(startSignDate), new Date(endSignDate)]
                  : []
              }
              placeholder="Select Date"
              onChange={onChangeSignDate}
            />
          }
          onClick={() => handleSortClick("event.signDate")}
        >
          Sign Date{" "}
          <SortDirectionIcon
            {...{ order, isExpanded: sort === "event.signDate" }}
          />
        </TableCellWrapper>

        <TableCellWrapper
          isHidden={!filterColArray?.includes("invoiced") || isStaffer}
          extraOption={
            <TableExtraOption
              type="select"
              placeholder="Select"
              data={[
                { label: "Invoiced", value: "true" },
                { label: "Non Invoiced", value: "false" },
              ]}
              searchable={false}
              value={String(filter?.isInvoiced)}
              onChange={(values) => handleFilterChange("isInvoiced", values)}
              onClean={() => handleClean("isInvoiced")}
            />
          }
          onClick={() => handleSortClick("event.isInvoiced")}
        >
          Invoiced
          <SortDirectionIcon
            {...{ order, isExpanded: sort === "event.isInvoiced" }}
          />
        </TableCellWrapper>

        <TableCellWrapper
          isHidden={!filterColArray?.includes("sync") || isStaffer}
          extraOption={<GoogleIcon className="custom-header-icon" />}
        >
          GOOGLE
        </TableCellWrapper>

        <TableCellWrapper
          isHidden={!filterColArray?.includes("delete") || isStaffer}
          extraOption={<ClearIcon className="custom-header-icon" />}
        >
          DELETE
        </TableCellWrapper>
      </TableRow>
    </TableHead>
  );
};

export default CustomHeader;
