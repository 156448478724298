import { get, post } from "./ServicesApi";

const createSchool = (body) => {
  const schoolPromise = post("/v1/school", body);
  return schoolPromise;
};

const getSchools = (body) => {
  const schoolPromise = get("/v1/school", body);
  return schoolPromise;
};

export { getSchools, createSchool };
