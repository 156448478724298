import { destroy, get, patch, post } from "./ServicesApi";

const employeeDetail = () => {
  const employeePromise = get("/v1/employee/id");
  return employeePromise;
};

const getAllEmployee = (params) => {
  const employeePromise = get("/v1/employee", { params });
  return employeePromise;
};

const getAllEmployeeUncondition = (params) => {
  const employeePromise = get("/v1/employee/all", { params });
  return employeePromise;
};
const getAllEmployeeByEvent = (eventId, params) => {
  const employeeEventPromise = get(`/v1/event/${eventId}/employees`, { params });
  return employeeEventPromise;
};

const getSalesEmployee = () => {
  const employeePromise = get(
    "/v1/employee/?roles=Event Director,Event Director Lead,Event Director Lead Auditor"
  );
  return employeePromise;
};

const updateEmployeeDetail = (id, body) => {
  const employeesPromise = patch(`/v1/employees/${id}`, body);
  return employeesPromise;
};

const deleteEmployee = (id) => {
  const employeesPromise = destroy(`/v1/employees/${id}`);
  return employeesPromise;
};

const createEmployee = (body) => {
  const employeesPromise = post(`/v1/employee`, body);
  return employeesPromise;
};

export {
  employeeDetail,
  getAllEmployeeByEvent,
  getAllEmployeeUncondition,
  deleteEmployee,
  createEmployee,
  updateEmployeeDetail,
  getAllEmployee,
  getSalesEmployee,
};
